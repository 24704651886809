@tailwind components;

@layer components {
  .rc-slider {
    @apply relative
      w-full h-2 rounded-sm
      touch-none;
  }

  .rc-slider > * {
    @apply absolute h-full;
  }

  .active > .rc-slider > .rc-slider-rail {
    @apply cursor-pointer;
  }

  .scrollbar > .rc-slider > .rc-slider-rail {
    @apply cursor-default;
  }

  .rc-slider > .rc-slider-rail {
    @apply w-full after:block after:w-3 after:h-3
    after:bg-gray-whisper after:rounded-full after:border after:border-white
    after:-mt-0.5 after:absolute after:right-0;
  }

  .label-slider-rail .rc-slider > .rc-slider-rail {
    @apply w-full after:block after:w-0 after:h-0
  }

  .scrollbar > .rc-slider > .rc-slider-rail {
    @apply bg-white/0;
  }

  .dot.active > .rc-slider > .rc-slider-track {
    @apply cursor-pointer;
  }

  .dot > .rc-slider > .rc-slider-track {
    @apply after:block after:w-3 after:h-3
    after:bg-gray7 after:rounded-full after:border after:border-white
    after:-mt-0.5
  }

  .active > .rc-slider > .rc-slider-handle {
    @apply cursor-pointer;
  }
}
