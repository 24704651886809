:root {
  --toast-top: 104px;
  --toast-right: calc((100vw - 1374px) / 2);
}

@media (max-width: 980px) {
  :root {
    --toast-top: 10px;
    --toast-right: 0px;
  }
}

@media (max-width: 1374px) {
  :root {
    --toast-top: 50px;
    --toast-right: 16px;
  }
}

.Toastify__toast {
  padding: 0;
  margin: 0;
  border: 1px solid #f0f0f0;
  box-shadow: none;
  min-height: 48px;
}

.Toastify__toast-body {
  padding: 0;
  margin: 0;
}

.Toastify__toast-body .Toastify__toast {
  border: none;
}

.Toastify__toast-container {
  width: max-content;
  padding: 0;
}

.Toastify__toast-container--top-right {
  top: var(--toast-top);
  right: var(--toast-right);
}

.Toastify__progress-bar {
  height: 0;
}

.Toastify__toast--default {
  box-shadow: 0px 26px 62px -10px rgba(102, 102, 102, 0.16);
}
