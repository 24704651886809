.react-datepicker__header {
  background-color: white;
  border-bottom: 0;
  padding: 0;
}

.react-datepicker-wrapper {
  height: 0;
  width: 0;
  display: none;
}

.react-datepicker__input-container {
  display: none;
}

.datepicker-popper.react-datepicker-popper {
  position: inherit !important;
  transform: none !important;
  padding-top: 0;
}

.react-datepicker {
  border-radius: 24px;
  border: 1px solid #F6F8F9;
  overflow: unset;
  font-family: 'Urbanist', sans-serif;
}

.react-datepicker__day-names {
  margin-top: 12px;
  display: flex;
}

.react-datepicker__day-name {
  color: #A0A0A0;
  font-weight: 600;
  margin: 0;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker__week {
  display: flex;
}

.react-datepicker__day {
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-bottom: 4px;
}

.react-datepicker__day:last-child {
  margin-bottom: 0;
}

.react-datepicker__month-container {
  float: unset;
  padding: 16px;
}

.react-datepicker__month {
  margin: 0;
  margin-top: 12px;
}

.datepicker-day.react-datepicker__day--today,
.datepicker-day.react-datepicker__day--keyboard-selected {
  background-color: transparent;
}

.datepicker-day.react-datepicker__day--outside-month:not(.react-datepicker__day--disabled) {
  color: #717171;
}

.datepicker-day.react-datepicker__day--in-range,
.datepicker-day.react-datepicker__day--in-selecting-range,
.datepicker-day.react-datepicker__day:hover:not(.react-datepicker__day--disabled) {
  background-color: var(--color-primary-10);
  border-radius: 0;
  color: #121212;
}

.datepicker-day.react-datepicker__day--selected,
.datepicker-day.react-datepicker__day--range-end {
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.datepicker-day.react-datepicker__day--selected > p,
.datepicker-day.react-datepicker__day--range-end > p {
  border-radius: 50%;
  color: white;
  background-color: var(--color-primary);
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.multiple.datepicker-day.react-datepicker__day--selected:not(.react-datepicker__day--selecting-range-end)::after,
.multiple.datepicker-day.react-datepicker__day--range-end::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--color-primary-10);
  height: 100%;
  width: 50%;
  z-index: 0;
}

.datepicker-day.react-datepicker__day--range-end::before {
  left: 0;
}

.react-datepicker__children-container {
  margin: 0;
  padding: 0;
  width: 100%
}
