@import url('./typography.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: #2261f5;
    --color-primary-80: #4e81f7;
    --color-primary-60: #7AA0F9;
    --color-primary-40: #A7C0FB;
    --color-primary-20: #D3DFFD;
    --color-primary-10: #E9EFFE;
    --color-primary-05: #F4F7FE;
    --color-primary-bg: #F1F3F6;
  }

  html {
    font-family: 'Urbanist', sans-serif;
    scrollbar-gutter: stable both-edges;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    right: 0;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    width: 8px;
    height: 8px;
    border-radius: 30px;
    opacity: 0.5;
    background: #D9D9D9;
    border-color: transparent;
    background-clip: padding-box;
    border: 2px solid transparent;
    border-left: 0;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  .scroll-hidden::-webkit-scrollbar,
  .scroll-hidden::-webkit-scrollbar-thumb {
    display: none;
  }

  #range-slider {
    margin: auto;
    width: 100%;
    height: 143px;
    background-color: transparent;
    overflow: hidden;
  }

  #range-slider.range-slider .range-slider__range {
    background: transparent !important;
    border-right: 2px solid var(--color-primary) !important;
    border-left: 2px solid var(--color-primary) !important;
    box-sizing: border-box !important;
    position: relative;
  }

  #range-slider.range-slider .range-slider__range::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: var(--color-primary) !important;
    opacity: 0.1 !important;
  }

  #range-slider.range-slider .range-slider__thumb {
    width: 31px;
    height: 31px;
    border-radius: 1000px;
    background: url('../icons/DragWhite.svg') var(--color-primary);
    background-repeat: no-repeat;
    background-position: center;
  }
}
