@tailwind components;

@layer components {
  .nav-list {
    @apply flex
      flex-row
      gap-x-6
      font-normal;
  }

  .nav-item {
    @apply relative max-w-max py-2
      whitespace-nowrap
  }

  .nav-item--selected {
    @apply font-medium text-button-blue text-17-26 relative whitespace-nowrap
      after:opacity-100;
  }
}
