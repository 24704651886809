@font-face {
  font-family: "DM Sans";
  src: local("DMSans"),
    url("../fonts/DMSans/DMSans_18pt-Regular.ttf")
    format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Urbanist";
  src: local("Urbanist"),
    url("../fonts/Urbanist/Urbanist-Regular.ttf")
    format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: 'Urbanist';
  src: local('Urbanist'),
    url('../fonts/Urbanist/Urbanist-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: "Urbanist";
  src: local("Urbanist"),
    url("../fonts/Urbanist/Urbanist-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: 'Urbanist';
  src: local('Urbanist'),
    url('../fonts/Urbanist/Urbanist-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url('../fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}
