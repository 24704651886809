.label-tooltip {
  color: white;
  position: fixed;
  background-color: #717171;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  padding: 8px;
  border-radius: 2px;
  transform: translateX(-50%);
  pointer-events: none;
  transition: all .3s ease;
}